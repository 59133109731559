<template>
  <div class="container " style="background:#FFF">
    <div class="textC MT_30">
        <img class="W_70" src="../assets/logo@2x.png" alt="">
    </div>
      <van-divider :style="{ color: '#B1B5BE', fontSize:'1.2rem', fontWeight:800, borderColor: '#B1B5BE', padding: '0 16px' }">
        您的投后管理中心
        <!-- <div class="fontS_20 font_weight_bold MT_5 textC" style="color:#B1B5BE">萝卜猎手投后管理中心</div> -->
      </van-divider>
      <van-field
        class="MT_15"
        v-model="phone"
        label="手机号"
        placeholder="请输入手机号"
      />
      <van-field
        style="margin-top:1rem"
        type="password"
        v-model="password"
        label="密码"
        placeholder="请输入密码"
      />
    
    <div class="W_100 textC loginBtn1" @click="handlelogin">登录</div>
  </div>
</template>
<script>
import {mapState,mapActions} from "vuex"
import { Toast } from 'vant';
export default {
  data(){
    return {
      phone:"",
      password:""
    }
  },
  computed:{
    ...mapState({
      userInfo:  (state) => state.userInfo,
    }),
  },
  mounted(){
   if(this.userInfo){
    this.$router.push("/index")
   }
  },
  methods:{
    ...mapActions(["login"]),
    handlelogin(){
      if(this.phone == '' || this.password == ''){
        Toast.fail("请输入");
        return
      }
      this.login({
        reqType: "login", //请求类型
        account: this.phone, //账户名
        password: this.password //密码
      }).then((res)=>{
        if(res.code == 0){
          Toast.success("登录成功！")
          setTimeout(()=>{
            this.$router.push("/index")
          },500)
          this.$store.commit("SET_USER_INFO",res.data.accountInfo)
          this.$store.commit("SET_COMPANY_NAME",res.data.accountInfo.enterprise_name)
          this.$store.commit("SET_COMPANY_AVATAR",res.data.accountInfo.avatar_url)
          this.$store.commit("SET_COMPANY_MAN",res.data.accountInfo.enterprise_manager) 
        } else {
          Toast.fail(res.msg);
        }
      })
      
    },

  }
}
</script>
<style scoped>
 
.loginBtn1{
  width: 100%;
  height: 3rem;
  line-height: 3rem;
  background: #151B62;
  border-radius: 1rem;
  color:#fff;
  margin-top:10rem;
}
</style>